.container {
  display: grid;
  width: 100%;
  height: 100%;
  min-height: 800px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 5fr 1fr 1fr 1fr 2fr;
  grid-gap: 1rem;
  grid-template-areas:
      "content-detail content-detail content-detail"
      "content-1 content-2 map"
      "content-3 content-4 map"
      "content-5 content-6 map"
      "footer footer footer";
}

@media screen and (max-width: 1000px) {
    .container {
        display: grid;
        width: 100%;
        height: 100%;
        min-height: 800px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 9fr 2fr 2fr 2fr 9fr 6fr 1fr;
        grid-gap: 1rem;
        grid-template-areas:
            "content-detail content-detail"
            "content-1 content-2"
            "content-3 content-4"
            "content-5 content-6"
            "map map"
            "footer footer";
    }

    .content-detail {
        margin-right: -1rem;
    }

    /* .content-detail > div {
        min-width: 1000px;
    } */
}
/* .content-detail div {
    min-width: 1000px;
} */

.content-1 {
  grid-area: content-1;
}

.content-2 {
  grid-area: content-2;
}

.content-3 {
  grid-area: content-3;
}

.content-4 {
  grid-area: content-4;
}

.content-5 {
  grid-area: content-5;
}

.content-6 {
  grid-area: content-6;
}

.map {
  grid-area: map;
}

.footer {
  grid-area: footer;
}

/* OTHER STYLES */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h3 {
  font-weight: normal;
}

h5 {
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-right: auto;
}

p {
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-right: auto;
}

#root {
  position: absolute;
  width: 100%;
  height: 180%;
  box-sizing: border-box;
  background-color: #FFF;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  #root {
    position: absolute;
    width: 100%;
    height: 220%;
    box-sizing: border-box;
    background-color: #FFF;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow: hidden;
  }
}

.item {
  position: relative;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
}

.item:not(.content-detail).active {
    cursor: pointer;
    border: 2px solid #E1B648;
}

.item:not(.active):hover {
    cursor: pointer;
    border: 2px solid #E1B648;
}

.content-1,
.content-2,
.content-3,
.content-4,
.content-5,
.content-6 {
    background-color: #FFF;
    border-radius: 10px;
    border: 2px solid #006eaa;
    padding-top: 30px;
}

.content-detail {
    grid-area: content-detail;
    /* overflow: hidden; */
    /* overflow-x: auto !important; */
    padding-bottom: 1%;
}

.item.content-detail:hover {
    border: none;
    cursor: initial;
}

.map {
  position: relative;
  background-color: #2E6CA5;
  border-radius: 10px;
}

.map .mapTitle {
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 1;
}

.footer {
  background-color: #F0F2F4;
  color: black;
  flex-direction: column;
  padding: 10px;
  align-items: left !important;
}

.header {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -10px;
  background-color: rgb(73, 73, 73);
  height: 5%;
  display: flex;
  align-items: center;
  color: white;
  box-sizing: border-box;
  padding: 8px;
}

.header-text {
  padding: 0 2%;
  float: right;
  margin-left: auto; 
  margin-right: 0;
}

.logo {
  height: 60%;
  padding: 0 2%;
}
